import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { AppService } from '../app.service';
import { localStorageToken, sessionStorageToken } from '../providers';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  forgotPasswordForm: FormGroup;
  errorMessage: String = '';
  responseMessage: String = '';
  responseMessageLogin = '';
  showForgetPasswordForm = false;
  showToken = false;
  customPath = '';

  constructor(
 	 private loginService: LoginService,
 	 private appService: AppService,
	  private route: Router,
	  private toastr: ToastrService,
 	 @Inject(localStorageToken) private localStorage: any,
 	 @Inject(sessionStorageToken) private sessionStorage: any,
  ) { }

  	ngOnInit() {
		this.customPath = this.route.url;

		if(this.customPath.includes('/token/')) {
			console.log(this.customPath.split('/token/').pop());
			const token = this.customPath.split('/token/').pop();
			
			this.loginService.isLoggedIn = true;
			this.appService.storage = localStorage;
					
			// localStorage.removeItem('Auth-Token');
			// sessionStorage.removeItem('Auth-Token');
			this.appService.storage.setItem('Auth-Token', token);
					
			this.route.navigate(['/dashboard']);
			// this.loginService.checkAuth().subscribe(
			// 	(result: any) => {
			// 		this.loginService.auth(true);
			// 		this.loginService.user = result.json();
			// 	},
			// 	(error: any) => {
			// 		this.loginService.auth(false);
			// 		this.route.navigate(['/login']);
			// 	},
			// );

		} 

		this.loginForm = new FormGroup({
			username : new FormControl('', Validators.required),
			password : new FormControl('', Validators.required),
			token : new FormControl(''),
			rememberMe : new FormControl('', Validators.required),
		});

		this.forgotPasswordForm = new FormGroup({
		email : new FormControl('', Validators.email),
		username: new FormControl('')
		});
	}

  	doLogin() {
		if(this.customPath == '/login/devicelab'){
			this.errorMessage = '';
			this.loginService.doLogin(this.loginForm.value.username, this.loginForm.value.password, this.loginForm.value.rememberMe, this.loginForm.value.token)
			.subscribe(
				(result: any) => {
				let resp = result.json();

				// if(resp.mfa){
				// 	this.showToken = true;
				// 	this.toastr.warning(resp.message);  
				// }else{
					this.loginService.isLoggedIn = true;
					this.appService.storage = localStorage;
					
					// localStorage.removeItem('Auth-Token');
					// sessionStorage.removeItem('Auth-Token');
					this.appService.storage.setItem('Auth-Token', result.json().token);
					
					location.assign('/');
				// }
				},
				(error: any) => {
					this.errorMessage = error.json().error;
					this.toastr.warning(error.json().error);  
				},
			);
		} else {
			this.errorMessage = '';
			this.loginService.doLoginOAM()
			.subscribe((result: any) => {
				let resp = result.json();

				console.log(resp)
				window.location.assign(resp);
				// this.loginService.isLoggedIn = true;
				// this.appService.storage = localStorage;
					
				// localStorage.removeItem('Auth-Token');
				// sessionStorage.removeItem('Auth-Token');
				// this.appService.storage.setItem('Auth-Token', result.json().token);
					
				// location.reload();
				
			}, (error: any) => {
				this.errorMessage = error.json();
				this.toastr.warning(error.json());  
			},);
		}
  	}

  sendEmail() {
	if (this.forgotPasswordForm.status === 'VALID') {
		this.responseMessage = 'O email está sendo enviado. Aguarde...';
	  	this.loginService.forgotPassword(this.forgotPasswordForm.get('email').value, this.forgotPasswordForm.get('username').value)
	  				   .subscribe(
						(result: any) => {
							this.responseMessage = '';
							this.responseMessageLogin = 'Email enviado com sucesso!';
							setTimeout(() => this.responseMessageLogin = '', 3000);
							this.forgotPasswordForm.reset();
							this.showForgetPasswordForm = false;
						},
						(error: any) => {
							this.responseMessage = '';
							this.errorMessage = error.json().message;
						}
						);
	} else {
		this.errorMessage = 'Invalid format email';
		setTimeout(() => this.errorMessage = '', 2000);
	}
  }

  voltar() {
	  this.showForgetPasswordForm = false;
  }
}
