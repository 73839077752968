import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login/login.service';
import { ShowcaseService } from './admin/showcase/showcase.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
	constructor(private router: Router, private loginService: LoginService, private showcaseService: ShowcaseService) {   
	let subsc = router.events.subscribe((event: any) => {
			console.log('Loging')
			this.showcaseService.setupSocket();
			if (this.loginService.isLoggedIn && event.url === '/') {
			
			}else if(!this.loginService.isLoggedIn && (event.url.includes('change-password') || event.url.includes('/token/'))){

			} else if (this.loginService.isLoggedIn && (event.url === '/login' || event.url === '/login/forgot')) {
				this.router.navigate(['/dashboard']);
			} else if (!this.loginService.isLoggedIn && event.url !== '/login' && event.url !== '/login/forgot') {
				this.router.navigate(['/login']);
			}
			subsc.unsubscribe();
 		});
 	}
}
